import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  EnergyClassId,
  useCreateEnergyClass,
  useDeleteEnergyClass,
  useGetEnergyClasses,
  useUpdateEnergyClass
} from '@api/endpoints/settings'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Box } from '@mui/system'
import { Button } from '@controls/Button'
import { Dictionary, DictionaryItem } from '@controls/DictionaryForm'

export const EnergyClassesPage: React.FC = () => {
  const { t } = useTranslation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const getEnergyClasses = useGetEnergyClasses({ onError: defaultErrorHandler })
  const deleteEnergyClass = useDeleteEnergyClass({ onError: defaultErrorHandler })
  const createEnergyClass = useCreateEnergyClass({ onError: defaultErrorHandler })
  const updateEnergyClass = useUpdateEnergyClass({ onError: defaultErrorHandler })

  const loading =
    getEnergyClasses.isFetching ||
    deleteEnergyClass.isLoading ||
    createEnergyClass.isLoading ||
    updateEnergyClass.isLoading

  const tableData: ReadonlyArray<DictionaryItem<EnergyClassId>> =
    getEnergyClasses.data?.map((row) => ({
      id: row.id,
      eng: row.title.eng,
      est: row.title.est,
      rus: row.title.rus,
      createdAt: row.createdAt
    })) ?? []

  return (
    <div>
      <h1>{t('pages.admin.energy_classes.title')}</h1>
      <Box display="flex" flexDirection="column" rowGap={4}>
        <Dictionary
          title={t('pages.admin.energy_classes.fields.title')}
          loading={loading}
          tableData={tableData}
          onDelete={deleteEnergyClass.mutate}
          onInsert={createEnergyClass.mutate}
          onUpdate={updateEnergyClass.mutate}
        />
      </Box>
      <Box display="flex" columnGap={2} mt={2}>
        <Button disabled={loading} onClick={() => getEnergyClasses.refetch()}>
          {t('global.buttons.refresh')}
        </Button>
      </Box>
    </div>
  )
}
